.incometop {
  background: linear-gradient(rgb(49, 52, 252, 0.8) 0%, rgb(49, 52, 252) 100%);
  padding: 80px 16px 16px 16px;
  background-image: url(/public/img/home/homebg.png);

  img {
    width: 100%;
    border-radius: 20px;
  }
}
.title {
  font-size: 18px;
  font-weight: 800;
}
.burn0 {
  width: 100vw;
  height: 150px;
  right: 0;
  bottom: 50%;

  position: absolute;
  margin-top: -140px;
  background-image: linear-gradient(
    179deg,
    rgb(94, 20, 223, 0.1) 1%,
    /* rgba(17, 19, 25, 0.05) 17%,
    rgba(17, 19, 25, 0.2) 31%,
    rgba(17, 19, 25, 0.39) 44%,
    rgba(17, 19, 25, 0.61) 56%,
    rgba(17, 19, 25, 0.8) 69%,
    rgba(17, 19, 25, 0.95) 83%,
    rgb(0, 0, 0) 99% */
  );
  /* filter: blur(50px); */
  /* z-index: 1; */
  /* z-index: -1; */
}
.burn {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  top: 50%;

  right: 0;
  margin-right: -150px;
  margin-bottom: -150px;
  filter: blur(180px);
  z-index: -1;
}
.burn3 {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  bottom: 0;
  right: 0;
  margin-right: -150px;
  z-index: -1;
  filter: blur(180px);
}
.burn4 {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  bottom: 0;
  left: 0;
  margin-left: -150px;
  z-index: -1;
  filter: blur(180px);
}
.burn2 {
  position: absolute;
  width: 100vw;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  margin-top: -220px;
  left: 0;
  margin-left: -50vw;
  z-index: -1;
  filter: blur(180px);
}
.infos {
  margin: 1rem 0;
  padding: 1rem 1px 1px 1px;
  display: grid;
  position: relative;
  z-index: 2;
  gap: 10px;
  color: #fff;
  border-radius: 12px;
  background: linear-gradient(
      95.31deg,
      rgba(0, 0, 0, 0.8) -3.76%,
      rgba(0, 0, 0, 0.7) 98.27%
    ),
    linear-gradient(102.48deg, #6854e8 49.73%, rgba(173, 172, 255, 0.3) 116.4%);
  border: 1px solid rgb(104 84 232 / 1);
  & img {
    width: 32px;
    height: 32px;
  }
  .m {
    background-color: #ffe800;
    width: 32px;
    height: 32px;
    border-radius: 36px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-weight: bold;
    font-size: 22px;
  }
  .add {
    width: 20px;
    height: 20px;
    background-color: #fff;
    color: #3b0bc7;
    border: 1px solid #fff;
    margin-left: -6px;
    margin-right: -6px;
    border-radius: 27px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.center {
  display: flex;
  align-items: center;
}
.pl {
  padding-left: 1rem;
}
.mx {
  margin-left: 1rem;
  margin-right: 1rem;
}
.info {
  display: grid;
  border-radius: 8px;
  padding: 1rem 0;
  gap: 16px;
  background-image: linear-gradient(339.91deg, #702ce6 0.95%, #590ddc 99.28%);
  & > span {
    font-size: 17px;
    font-weight: 800;
    padding-left: 1rem;
  }
}

.infodetail {
  width: 50%;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 1px;
}
.infobar {
  display: grid;
  border: 1px solid rgb(104 84 232 / 1);
  border-radius: 8px;
  padding: 1rem;
  gap: 16px;
  background: linear-gradient(
      95.31deg,
      rgba(0, 0, 0, 0.8) -3.76%,
      rgba(0, 0, 0, 0.7) 98.27%
    ),
    linear-gradient(102.48deg, #6854e8 49.73%, rgba(173, 172, 255, 0.3) 116.4%);
  border: 1px solid rgb(104 84 232 / 1);
  .bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    & > :first-child,
    & > :last-child {
      display: grid;
      place-items: center;
      gap: 10px;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: rgb(104 84 232 / 1);
  }
}
.incomebody {
  margin: 1rem;
  .details {
    margin-top: 1rem;
    padding: 1rem;
    /* background: linear-gradient(
        95.31deg,
        rgba(0, 0, 0, 0.8) -3.76%,
        rgba(0, 0, 0, 0.7) 98.27%
      ),
      linear-gradient(
        102.48deg,
        #6854e8 49.73%,
        rgba(173, 172, 255, 0.3) 116.4%
      ); */
    border: 1px solid rgb(104 84 232 / 1);
    border: 1px solid rgb(104 84 232 / 1);
    border-radius: 8px;
    display: grid;
    gap: 16px;
    .barn {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    & > :last-child {
      margin-top: 1rem;
    }
  }
}
