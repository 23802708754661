.top {
  position: relative;
  margin-top: -100px;
  margin-bottom: 200px;
  & > :first-child {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 800;
    gap: 4px;
    & > img {
      width: 24px;
      height: 24px;
    }
  }
}
.topbar {
  display: grid;
  gap: 24px;
  position: relative;
  padding: 16px;
  margin: 16px;
  background-image: (
      95.31deg,
      rgba(0, 0, 0, 0.8) -3.76%,
      rgba(0, 0, 0, 0.7) 98.27%
    ),
    linear-gradient(102.48deg, #6854e8 49.73%, rgba(173, 172, 255, 0.3) 116.4%);
  border: 1px solid rgb(104 84 232/1);
  border-radius: 12px;
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;

  z-index: 2;
}
.burn0 {
  width: 100vw;
  height: 150px;
  right: 0;
  position: absolute;
  margin-top: -140px;
  background-image: linear-gradient(
    179deg,
    rgb(94, 20, 223, 0.1) 1%,
    /* rgba(17, 19, 25, 0.05) 17%,
    rgba(17, 19, 25, 0.2) 31%,
    rgba(17, 19, 25, 0.39) 44%,
    rgba(17, 19, 25, 0.61) 56%,
    rgba(17, 19, 25, 0.8) 69%,
    rgba(17, 19, 25, 0.95) 83%,
    rgb(0, 0, 0) 99% */
  );
  /* filter: blur(50px); */
  /* z-index: 1; */
  /* z-index: -1; */
}
.burn {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  top: 50%;

  right: 0;
  margin-right: -150px;
  margin-bottom: -150px;
  filter: blur(180px);
}
.burn3 {
  position: absolute;
  width: 302px;
  height: 502px;
  background-color: #5e14df;
  /* background-color: #fff; */
  bottom: 0;
  right: 0;
  margin-right: -150px;

  filter: blur(180px);
  /* z-index: -1; */
}
.burn4 {
  position: absolute;
  width: 302px;
  height: 502px;
  background-color: #5e14df;
  /* background-color: #fff; */
  bottom: 0;
  left: 0;
  margin-left: -150px;
  /* z-index: -1; */
  filter: blur(180px);
}
.burn2 {
  position: absolute;
  width: 100vw;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  margin-top: -220px;
  left: 0;
  margin-left: -50vw;

  filter: blur(180px);
}

.invitetop {
  background: linear-gradient(rgb(49, 52, 252, 0.8) 0%, rgb(49, 52, 252) 100%);
  padding: 80px 16px 120px 16px;
  background-image: url(/public/img/home/homebg.png);

  img {
    width: 100%;
    border-radius: 20px;
  }
}
.incomebody {
  position: relative;
  margin: 1rem;
  margin-top: -100px;
  z-index: 10;
  .details {
    margin-top: 1rem;
    padding: 1rem;
    background: linear-gradient(
        95.31deg,
        rgba(0, 0, 0, 0.8) -3.76%,
        rgba(0, 0, 0, 0.7) 98.27%
      ),
      linear-gradient(102.48deg, #6854e8 49.73%, #5e14df 116.4%);
    /* background-image: linear-gradient(
      179deg,
      rgb(94, 20, 223, 0.1) 1%,
      rgba(17, 19, 25, 0.05) 17%,
      rgba(17, 19, 25, 0.2) 31%,
      rgba(17, 19, 25, 0.39) 44%,
      rgba(17, 19, 25, 0.61) 56%,
      rgba(17, 19, 25, 0.8) 69%,
      rgba(17, 19, 25, 0.95) 83%,
      rgb(0, 0, 0) 99%
    ); */
    border: 1px solid rgb(104 84 232 / 1);
    border: 1px solid rgb(104 84 232 / 1);
    border-radius: 8px;
    display: grid;
    gap: 16px;
    .barn {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    & > :last-child {
      margin-top: 1rem;
    }
  }
}
.upbtn {
  font-family: zixiaohunxingjililiangti;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #3b0bc7;
  background: #fff;
  border: 0.5px solid #ffffff1f;
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
