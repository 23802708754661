.homeli {
  position: absolute;
  width: 655.71px;
  height: 330px;

  background: linear-gradient(
    0deg,
    rgba(210, 249, 190, 0.2) 0%,
    rgba(210, 249, 190, 0) 100%
  );
  opacity: 0.4;
}

.mt20 {
  margin-top: 20px;
}
.bodyimg {
  width: 100%;
}

.homeburn1 {
  /* Ellipse 2597 */

  position: absolute;
  width: 120px;
  height: 120px;
  left: 294px;
  top: 255px;

  background: #ffe800;
  filter: blur(100px);
}
.p16 {
  position: absolute;

  margin: 0 16px;
}
.mt10 {
  padding-top: 10px;
}
.mt11 {
  padding-bottom: 20px;
}
.flex_t {
  display: flex;
  margin-top: 36px;
}
.flex_gc {
  display: flex;
  gap: 8px;
  align-items: center;
}
.flex_bc {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.font2 {
  font-family: zihunlaozhaopai_trial;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  margin-left: 10px;
}

.pa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 86%;
  margin-top: -70px;
}
.pa1 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 86%;
  margin-bottom: -76px;
}
.g16 {
  box-sizing: border-box;
  width: 144px;
  display: grid;
  gap: 16px;
}
.g16dpos {
  box-sizing: border-box;
  width: 154px;

  display: grid;
  gap: 16px;
}
.homewk {
  box-sizing: border-box;
  font-family: zixiaohunxingjililiangti;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  height: 41px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  border-radius: 12px;
}
.heightBig {
  height: 51px;
}
.leftimg {
  width: 123px;
  height: 123px;
}
.g6 {
  display: grid;
  gap: 6px;
}

.fcolor1 {
  border: 1px solid #d4fd48;
  color: #d4fd48;
}
.fcolor2 {
  color: #ffffff99;
  border: 0.5px solid #ffffff;
}
.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 320px;
  top: 710px;
}
.loading0 {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 320px;
  top: 300px;
}
.mt22 {
  margin-top: 22%;
  height: 60px;
  display: grid;
  place-items: center;
  gap: 10px;
}
.loadingimg {
  width: 51px;
  height: 61px;
}
.loadingfont {
  font-family: zihunlaozhaopai_trial;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}
.p5 {
  opacity: 0.5;
}
.pb {
}
.hometop {
  background: linear-gradient(rgb(49, 52, 252, 0.8) 0%, rgb(49, 52, 252) 100%);
  padding: 80px 16px 120px 16px;
  background-image: url(/public/img/home/homebg.png);
  position: relative;
  img {
    width: 100%;
    border-radius: 20px;
  }
}
.mtinv {
  margin-top: -110px;
  padding: 0 16px;
  z-index: 2;
  position: relative;
}
.body {
  display: grid;
  place-items: center;
  gap: 20px;
  padding: 16px;
}
.bodytitle {
  margin-left: 16px;
  font-weight: bold;
}
.bodybar {
  display: grid;
  position: relative;
  padding-bottom: 16px;
  border-radius: 12px;
  margin-top: 10px;
  background-image: linear-gradient(
      95.31deg,
      rgba(0, 0, 0, 0.8) -3.76%,
      rgba(0, 0, 0, 0.7) 98.27%
    ),
    linear-gradient(102.48deg, #6854e8 49.73%, rgba(173, 172, 255, 0.3) 116.4%);
  font-weight: 600;
  border: 1px solid rgb(104 84 232/1);
}
.burn0 {
  width: 100vw;
  height: 50px;
  right: 0;
  position: absolute;
  margin-top: -40px;
  background-image: linear-gradient(
    179deg,
    rgb(94, 20, 223, 0.1) 1%,
    /* rgba(17, 19, 25, 0.05) 17%, */ /* rgba(17, 19, 25, 0.2) 31%, */
      /* rgba(17, 19, 25, 0.39) 44%, */ /* rgba(17, 19, 25, 0.61) 56%, */
      /* rgba(17, 19, 25, 0.8) 69%, */ /* rgba(17, 19, 25, 0.95) 83%, */
      /* rgb(0, 0, 0) 99% */
  );
  /* filter: blur(50px); */
  /* z-index: 1; */
  /* z-index: -1; */
}
.burn1 {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  top: 0;
  right: 0;
  margin-right: -150px;
  margin-top: 60px;
  filter: blur(180px);
  z-index: -1;
}
.burn2 {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  top: 0;
  left: 0;
  margin-left: -150px;
  margin-top: 60px;
  filter: blur(180px);
  z-index: -1;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lptop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 12px;
  background: linear-gradient(339.91deg, #702ce6 0.95%, #590ddc 99.28%);
  & > img {
    width: 36px;
    height: 36px;
  }
  .m {
    background-color: #ffe800;
    width: 32px;
    height: 32px;
    border-radius: 36px;
    border: 2px solid #fff;

    margin-right: 10px;
    font-weight: bold;
    font-size: 24px;
  }
  .add {
    width: 20px;
    height: 20px;
    background-color: #fff;
    color: #3b0bc7;
    border: 1px solid #fff;
    margin-left: -6px;
    margin-right: -6px;
    border-radius: 27px;
    z-index: 2;
  }
}

.suffix {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputbar {
  padding: 10px 16px;
}
.input {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding-right: 16px;
  background-color: rgb(104 33 227/1);
  .innum {
    background-color: rgb(17 3 57/1);
    margin-left: 1px;
    margin-right: 16px;
    height: calc(100% - 2px);
    width: 100%;
    border-radius: 8px 12px 12px 8px;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
}
.info {
  font-size: 12px;
  margin-top: 4px;
}
.lpdata {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}
.waitebtn {
  background-color: rgb(195, 195, 195);
  border: 1px solid rgb(125, 125, 125);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  border-radius: 12px;
  color: #8448fd;
}
.font1 {
  font-size: 12px;
  color: #d9d9d9;
}
.font2 {
  font-size: 20px;
  margin-top: 4px;

  font-weight: 600;
}
.btnbar {
  padding: 0 16px;
}
.burn {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  bottom: 0;
  right: 0;
  margin-right: -150px;
  margin-bottom: -150px;
  filter: blur(180px);
  z-index: -1;
}
.burn4 {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  bottom: 0;
  left: 0;
  margin-left: -150px;
  margin-bottom: -150px;
  filter: blur(180px);
  z-index: -1;
}
.lpbar {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
}
.top {
  position: relative;
  margin-top: 1rem;
  & > :first-child {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 800;
    gap: 4px;
    & > img {
      width: 24px;
      height: 24px;
    }
  }
}
.topbar {
  display: grid;
  gap: 24px;
  position: relative;
  padding: 16px;
  margin: 16px 0;
  background-image: linear-gradient(
      95.31deg,
      rgba(0, 0, 0, 0.8) -3.76%,
      rgba(0, 0, 0, 0.7) 98.27%
    ),
    linear-gradient(102.48deg, #6854e8 49.73%, rgba(173, 172, 255, 0.3) 116.4%);
  border: 1px solid rgb(104 84 232/1);
  border-radius: 12px;
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;

  z-index: 2;
}
.title {
  display: flex;
  align-items: center;
  gap: 4px;

  & > img {
    width: 22px;
    height: 22px;
  }
}
.inputbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0;
  & > span {
    white-space: nowrap;
  }
  .input {
    width: 100%;
    background-color: transparent;
    color: #fff;
    display: flex;
    align-items: center;
    height: 40px;
  }
}
.balance {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 8px;
  & > :last-child {
    color: red;
  }
}
.tips {
  color: red;
  text-align: center;
}
